<template>
  <div>
    <setting-global v-if="role == 'DOCTOR'"></setting-global>
    <settingTeamMemberProfile v-else></settingTeamMemberProfile>
  </div>
</template>

<script>
const settingGlobal = () => import("@/views/settingProfile");
const settingTeamMemberProfile = () =>
  import("@/views/dashboard/users/teamMemberProfile");

export default {
  components: {
    settingGlobal,
    settingTeamMemberProfile
  },
  data() {
    return {
      role: null
    };
  },
  async mounted() {
    const user = await this.$auth.getUserInfo();
    this.role = user.role;
  }
};
</script>
